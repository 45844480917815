<template>
  <div v-if="recommendProducts.length > 0" :class="$style.recommendation">
    <MoleculesConsumerHeading title="人気のおすすめ商品" :title-font-size-px="20" align="left" variant="sub" />
    <div :class="[$style.product_list, { [$style.center]: isCenter }]">
      <template v-for="product in recommendProducts">
        <AtomsConsumerLink
          v-if="product.skus.length > 0"
          :key="product.name"
          :url="getSiteProductPageUrl(product)"
          :area-label="product.name"
          :class="$style.item"
        >
          <div :class="$style.thumb">
            <AtomsConsumerImage
              :src="product.skus[0].main_image || ''"
              :alt="product.name"
              :class="$style.img"
              :width="184"
              :height="245"
            />
          </div>
          <div :class="$style.text_area">
            <p :class="$style.title">{{ product.name }}</p>
            <p :class="$style.title">{{ product.material }}</p>
            <p :class="$style.price">
              &yen; {{ priceWithTaxAndComma(product.skus[0].price, product.skus[0].consumption_tax_percentage) }}
              <span :class="$style.tax">(参考税込)</span>
            </p>
          </div>
        </AtomsConsumerLink>
      </template>
    </div>
  </div>
</template>

<script setup lang="ts">
import { priceWithTaxAndComma } from "@tential/ec-gql-schema/utils/functions/price"
import { RecommendProductFragmentFragment } from "~/types/type.generated"
import { getSiteProductPageUrl } from "~/utils/functions/product"

type Props = {
  recommendProducts: RecommendProductFragmentFragment[]
}

const props = withDefaults(defineProps<Props>(), {
  recommendProducts: () => [],
})

const isCenter = computed(() => props.recommendProducts.length < 3)
</script>

<style module scoped lang="scss">
.recommendation {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  .product_list {
    display: flex;
    gap: 1rem;
    &.center {
      justify-content: center;
    }
    .item {
      width: calc((100% - 0.5rem * 2) / 3);
      .thumb {
        overflow: hidden;
        .img {
          aspect-ratio: 184 / 245;
          object-fit: cover;
          height: 100%;
          width: 100%;
          border-radius: 0.375rem;
          @include background-skeleton-loading;
        }
      }
      .text_area {
        margin-top: 0.5rem;
        .title {
          @include main-font;
          font-size: $font-size-12;
          line-height: 1rem;
          margin-bottom: 0.25rem;
        }
        .price {
          @include main-font;
          color: $primary;
          font-size: $font-size-12;
          .tax {
            font-size: $font-size-10;
            font-weight: 300;
            line-height: 14px 3vw;
            margin-left: 0.25rem;
          }
        }
      }
    }
  }
}
</style>
