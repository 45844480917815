<template>
  <div :class="$style.main">
    <OrganismsHeader
      ref="baseSiteHeaderRef"
      :navigations="headerNavigations"
      :product-categories="productCategories"
      :header-banner="headerBanner"
      @on-click-search-sp="isShowSearchSideBarSP = true"
      @on-click-show-sp-nav="isShowSpSideNavbar = true"
    />

    <!-- SPメニュー -->
    <OrganismsSidebar
      :navigations="headerNavigations"
      :is-show="isShowSpSideNavbar"
      @close="isShowSpSideNavbar = false"
    />
    <div
      :class="[$style.sidebar_overlay, { [$style.is_show]: isShowSpSideNavbar }]"
      @click="isShowSpSideNavbar = false"
    />

    <!-- SP検索サイドバー -->
    <template v-if="productCategories">
      <div :class="[$style.search_sidebar, { [$style.is_show]: isShowSearchSideBarSP }]">
        <AtomsConsumerButton :class="$style.close" @click="closeSearchSideBarSP" />
        <OrganismsSearchForm :product-categories="productCategories" @on-close="closeSearchSideBarSP" />
      </div>
    </template>
    <div
      :class="[$style.search_sidebar_overlay, { [$style.is_show]: isShowSearchSideBarSP }]"
      @click="closeSearchSideBarSP"
    />

    <!-- サイドカート -->
    <CartSideCart v-if="!isOverseasPage" />
    <OverseasSideCart v-else></OverseasSideCart>
    <div :class="[$style.sidebar_overlay, { [$style.is_show]: cartState.isShowCart }]" @click="closeCart" />

    <!-- 共通モーダル -->
    <OrganismsConfirmDialog />
    <OrganismsToast />
    <CommonModalGetCouponModal />

    <div :class="$style.main_content" :style="{ 'padding-top': `${headerPaddingTop}px` }">
      <NuxtPage />
    </div>

    <OrganismsFooter :navigations="footerNavigations" />
  </div>
</template>

<script setup lang="ts">
import dayjs from "dayjs"
import { useResizeObserver } from "@vueuse/core"
import { useStore } from "~/stores"
import { useCart } from "~/composables/useCart"
import { useCustomMeta } from "~/composables/meta"
import { appendKarteScripts, removeKarteScripts } from "~/utils/functions/karte"
import { crossoverTag } from "~/utils/functions/affiliate"
import { criteoTag } from "~/utils/functions/crto"
import { yahooGeneralTag, yahooRetargetingScript, yahooRetargetingTag } from "~/utils/functions/yahoo"
import { mercariGeneralTag, mercariRetargetingTag } from "~/utils/functions/mercari"
import { fbPixel } from "~/utils/const/facebook"
import { MetaInfo, ScriptPropertySrc, ScriptPropertyText } from "~/types/meta"
import { ChannelService, ignoreChannelTalkPath } from "~/utils/channel_talk"
import { useOverseasStore } from "~/stores/overseas"
import { useAdTrackingCreateMutation, usePublicAdFindOneQuery } from "~/gql/urql.generated"
import type { ApiLayoutOneResponse } from "~/types/server/api/layout"

const store = useStore()
const baseSiteHeaderRef = ref<HTMLElement>()
const headerPaddingTop = ref<number>(0)
const route = useRoute()
const config = useRuntimeConfig()
const customMeta = useCustomMeta()
const nuxtApp = useNuxtApp()

const { cartState, closeCart } = useCart()

const { executeMutation: createAdTracking } = useAdTrackingCreateMutation()

const overseasStore = useOverseasStore()

const headerNavigations = computed(() => {
  const header = layoutData?.navigation.find((nav) => nav.layout_type === "header")
  return header?.navigations
})

const footerNavigations = computed(() => {
  const footer = layoutData?.navigation.find((nav) => nav.layout_type === "footer")
  return footer?.navigations
})
const { data } = await useAsyncData(async () => {
  return await $fetch<ApiLayoutOneResponse>("/api/layout/one", {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
  })
})
const layoutData = data.value?.status === 200 ? data.value.data : null

const productCategories = computed(() => {
  return layoutData?.productCategories
})

const headerBanner = computed(() => {
  return layoutData?.banner
})

const isShowSearchSideBarSP = ref(false)
const isShowSpSideNavbar = ref(false)
const closeSearchSideBarSP = () => {
  isShowSearchSideBarSP.value = false
}

const isOverseasPage = computed(() => {
  return overseasStore.isOverseasPage
})

const addRouteLog = (BASE_URL: string) => {
  store.addRouteLog({
    url: BASE_URL + route.fullPath,
    stamped_unixtime: dayjs().unix() * 1000,
  })
}

const addTrackingInformation = async (BASE_URL: string) => {
  // 参照先が自サイト以外の場合、トラフィックをセット
  if (!document.referrer.includes(`${BASE_URL}`) && store.trafficParams && !store.trafficParams.full_path) {
    store.setTrafficParams(document.referrer)
  }

  // 広告クエリパラメータがある場合、トラッキング情報をセット
  if (route.query.u) {
    try {
      // 広告トラッキング情報をセット
      store.trackingPost({
        ad_url: typeof route.query?.u === "string" ? route.query.u : String(route.query.u),
        to_url: route.fullPath,
      })

      // 広告情報を取得
      const { data: adResult } = await usePublicAdFindOneQuery({
        variables: {
          filter: {
            ad_url: store.trackingParams.ad_url,
          },
        },
      })

      if (adResult.value?.publicAdFindOne) {
        const adTracking = {
          ad_id: adResult.value.publicAdFindOne.document_id,
          url: store.trackingParams.to_url,
        }
        // 広告トラッキング情報を追加
        await createAdTracking({ record: adTracking })
      }
    } catch {
      console.error("広告トラッキング情報の取得・追加に失敗しました")
    }
  }
}

const { data: newrelic } = await useFetch("/api/newrelic")

const meta = (): MetaInfo => {
  const script: (ScriptPropertyText | ScriptPropertySrc)[] = []

  script.push({ innerHTML: newrelic.value || "" })
  script.push(fbPixel)
  script.push(yahooRetargetingTag)
  script.push(yahooRetargetingScript)
  script.push(yahooGeneralTag)
  script.push(criteoTag)
  script.push(crossoverTag)
  script.push(mercariRetargetingTag)
  script.push(mercariGeneralTag)
  // script.push(ugcCoreTag)
  return customMeta.fetch({
    script,
  })
}

const karteViewEvent = () => {
  const { vueApp } = nuxtApp
  if (vueApp.$nuxt.$router) {
    const isJournalPage = route.fullPath.includes("journals")
    if (!isJournalPage && window.krt && ["production"].includes(config.public.APP_ENV)) {
      window.krt("view")
    }
  }
}

const channelTalkViewEvent = () => {
  if (["production"].includes(config.public.APP_ENV)) {
    const ignoreChannelTalk = ignoreChannelTalkPath.some((value) => {
      return route.fullPath.includes(value)
    })
    const channel = new ChannelService()
    const pluginKey = config.public.CHANNEL_TALK_PLUGIN_KEY
    if (ignoreChannelTalk) {
      channel.hideChannelButton()
      return
    }
    if (window.ChannelTalkIsLoaded) return
    if (window.ChannelIOInitialized) {
      channel.boot({
        pluginKey,
      })
      channel.onShowMessenger(function onShowMessenger() {
        window.dataLayer?.push({ event: "show-channel-talk-messenger" })
      })
    } else {
      window.ChannelTalkIsLoaded = true
      const initializeChanelTalk = () => {
        channel.loadScript()
        channel.boot({
          pluginKey,
        })
        channel.onShowMessenger(function onShowMessenger() {
          window.dataLayer?.push({ event: "show-channel-talk-messenger" })
        })
        window.ChannelTalkIsLoaded = false
      }
      setTimeout(() => {
        initializeChanelTalk()
      }, 20 * 1000)
    }
  }
}

const getCouponCode = () => {
  if (route.query.coupon_code) {
    const coupon_code = typeof route.query.coupon_code === "string" ? route.query.coupon_code : ""
    const expired = dayjs().add(3, "week").unix()
    const payload = {
      couponCode: coupon_code,
      expired,
    }
    store.setCouponCode(payload)
  }
}

const setLocalUserId = () => {
  if (process.client) {
    const localUserId = localStorage.getItem("localUserId")
    if (localUserId) return
    const generateLocalUserId =
      parseInt(
        Math.ceil(Math.random() * Date.now())
          .toPrecision(20)
          .toString(),
      ) +
      "+" +
      Math.random().toString(32).substring(2)

    localStorage.setItem("localUserId", generateLocalUserId)
  }
}

onMounted(async () => {
  getCouponCode()
  addRouteLog(config.public.BASE_URL)
  await addTrackingInformation(config.public.BASE_URL)
  karteViewEvent()
  appendKarteScripts(route.fullPath)
  channelTalkViewEvent()
  setLocalUserId()
})

watch(
  () => isShowSearchSideBarSP.value,
  (value) => {
    if (value) {
      window.history.pushState(null, "", null)
      window.addEventListener("popstate", closeSearchSideBarSP)
    } else {
      window.removeEventListener("popstate", closeSearchSideBarSP)
    }
  },
)

watch(
  () => route.fullPath,
  () => {
    appendKarteScripts(route.fullPath)
    removeKarteScripts(route.fullPath)
    addRouteLog(config.public.BASE_URL)
    karteViewEvent()
    channelTalkViewEvent()
  },
)

// layouts配下でuseCartをInject
useResizeObserver(baseSiteHeaderRef, ([element]) => {
  const { height } = element.contentRect
  if (height > 0) headerPaddingTop.value = height
})

useHead(meta())
</script>

<style module lang="scss" scoped>
.main {
  min-width: 100%;
  min-height: 100vh;
  padding: 0;
  display: flex;
  flex-direction: column;

  .main_content {
    flex-grow: 1;
    padding-bottom: 80px;
    @include md {
      padding-bottom: 100px;
    }
  }

  .search_sidebar {
    position: fixed;
    top: 0;
    right: 0;
    z-index: 120;
    width: 85vw;
    height: 100vh;
    padding: 4.5rem 2rem 2rem;
    background: $primary-10;
    transform: translateX(100%);
    transition: transform 0.3s ease;
    &.is_show {
      transform: translateX(0);
    }
    .close {
      position: absolute;
      top: 30px;
      right: 30px;
      padding: 15px;
      cursor: pointer;
      &::before,
      &::after {
        position: absolute;
        top: 14px;
        left: 0px;
        width: 32px;
        height: 1px;
        content: "";
        background: $primary;
      }
      &::before {
        transform: rotate(45deg);
      }
      &::after {
        transform: rotate(-45deg);
      }
    }
  }

  .sidebar_overlay,
  .search_sidebar_overlay {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 100;
    display: none;
    width: 100%;
    height: 100%;
    background-color: rgba($primary, 0.85);
    &.is_show {
      display: block;
    }
  }
}
</style>
