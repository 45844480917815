<template>
  <div v-if="hasModalOpenRef" class="bmodal-content">
    <div class="bmodal-container">
      <div class="_icon-area">
        <AtomsConsumerTentialLogo variant="text" :width="140" :height="40" />
        <div class="_close" @click="toggleModal"></div>
      </div>
      <div id="get-coupon-modal">
        <h3>獲得したクーポンがあります</h3>
        <div v-for="(coupon, index) in couponsRef" :key="index" class="_coupon-modal-section">
          <div class="_card-area">
            <div v-if="coupon" class="_card">
              <div class="_coupon-ttl-area">
                <div class="_img-box">
                  <AtomImage src="/images/common/get-coupon-logo.svg" alt="" />
                </div>
                <div class="_text-box">
                  <div class="_coupon-name">{{ coupon.name }}</div>
                  <div class="_discount-rate">
                    {{ getDiscount(coupon) }}<span>{{ DiscountMethods[coupon.discount_method] }}</span
                    >OFF
                  </div>
                </div>
              </div>
              <div class="_coupon-detail-area">
                <div class="_coupon-detail">{{ coupon.coupon_text }}</div>
                <dl>
                  <dt>獲得可能期間:</dt>
                  <dd>
                    {{ formatDateSlash(coupon.started_unixtime) + ` ~ ` + formatDateSlash(coupon.expired_unixtime) }}
                  </dd>
                </dl>
                <dl v-if="coupon.expiration_days">
                  <dt>有効期限:</dt>
                  <dd>{{ coupon.expiration_days }}日間</dd>
                </dl>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="bmodal-overlay" @click="toggleModal"></div>
  </div>
</template>

<script lang="ts" setup>
import { formatDateSlash } from "@tential/ec-gql-schema/utils/functions/date"
import { DiscountMethods } from "@tential/ec-gql-schema/master/coupon"
import { CouponFragmentFragment } from "~/types/type.generated"

import { useStore } from "~/stores"
const store = useStore()
const couponsRef = ref<CouponFragmentFragment[]>([])
const hasModalOpenRef = ref(false)
const toggleModal = (): void => {
  hasModalOpenRef.value = !hasModalOpenRef.value
  if (!hasModalOpenRef.value) store.resetDistributedCoupon()
}
const fetchCouponData = (): void => {
  const distributedCoupon = store.distributedCoupon
  couponsRef.value = distributedCoupon.coupons
  if (couponsRef.value.length > 0 && !distributedCoupon.isNotModal) hasModalOpenRef.value = true
}
// 割引方式が百分率の場合は割引率を、固定金額の場合は割引金額を返す
const getDiscount = (coupon: CouponFragmentFragment): number => {
  if (coupon.discount_method === "percentage") return coupon.discount_rate || 0
  return coupon.discount_fixed_amount || 0
}
onMounted(() => {
  fetchCouponData()
})
watch(
  () => store.distributedCoupon,
  () => {
    fetchCouponData()
  },
  { deep: true },
)
</script>
