<template>
  <teleport to="body">
    <MoleculesConsumerModal v-model="store.isShow" :is-display-close-button="false">
      <div :class="$style.confirm">
        <p :class="$style.message">{{ store.message }}</p>
        <div :class="$style.dialog_buttons">
          <AtomsConsumerButton variant="secondary" block :class="$style.cancel_button" @click="store.hide">
            {{ !isOverseasPage ? "キャンセル" : "Cancel" }}
          </AtomsConsumerButton>
          <AtomsConsumerButton variant="primary" block :class="$style.ok_button" @click="store.agree">
            OK
          </AtomsConsumerButton>
        </div>
      </div>
    </MoleculesConsumerModal>
  </teleport>
</template>

<script setup lang="ts">
import { useOverseasStore } from "~/stores/overseas"
import { useConfirmDialogStore } from "~/stores/confirmDialog"
const store = useConfirmDialogStore()
const overseasStore = useOverseasStore()

const isOverseasPage = computed(() => {
  return overseasStore.isOverseasPage
})
</script>

<style scoped module lang="scss">
.confirm {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  align-items: center;
  justify-content: center;
  width: 90vw;
  max-width: 500px;
  height: auto;
  padding: 2rem 0 1.5rem;
  background-color: $white;
  .message {
    white-space: pre-line;
    text-align: center;
  }
  .dialog_buttons {
    width: 100%;
    display: flex;
    gap: 1rem;
    margin-top: 2rem;
  }
}
</style>
