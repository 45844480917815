<template>
  <ClientOnly>
    <footer :class="$style.footer">
      <div :class="$style.footer_inner">
        <div v-if="!isOverseasPage && !isSimple" :class="$style.footer_top">
          <div :class="$style.button_group">
            <AtomsConsumerLink v-if="!isSigned" url="/sign_up" :class="$style.link">
              <MoleculesConsumerButtonWithArrowRight variant="primary" block
                >新規会員登録</MoleculesConsumerButtonWithArrowRight
              >
            </AtomsConsumerLink>
            <AtomsConsumerLink :url="isSigned ? '/mypage' : '/sign_in'" :class="$style.link">
              <MoleculesConsumerButtonWithArrowRight variant="transparent" block>{{
                isSigned ? "マイページ" : "ログイン"
              }}</MoleculesConsumerButtonWithArrowRight>
            </AtomsConsumerLink>
            <AtomsConsumerLink url="/contact" :class="$style.link">
              <MoleculesConsumerButtonWithArrowRight variant="transparent" block
                >お問い合わせ</MoleculesConsumerButtonWithArrowRight
              >
            </AtomsConsumerLink>
          </div>
          <ul :class="$style.nav_links_group">
            <li v-for="navigation in navigations" :key="navigation.document_id" :class="$style.nav_links_title">
              {{ navigation.title }}
              <ul :class="$style.nav_links_wrap">
                <li :class="$style.nav_link_wrap">
                  <AtomsConsumerLink
                    v-for="nameAndUrl in navigation.category_urls"
                    :key="nameAndUrl?.name"
                    :url="nameAndUrl?.url"
                    :class="$style.nav_link"
                    >{{ nameAndUrl?.name }}</AtomsConsumerLink
                  >
                </li>
              </ul>
            </li>
          </ul>
          <div :class="$style.accordion_wrap">
            <MoleculesConsumerAccordion
              v-for="(navigation, index) in navigations"
              :key="index"
              :class="[
                $style.accordion,
                { [$style.last_accordion]: index === 2 },
                { [$style.active]: selectAccordions.includes(index) },
              ]"
              icon="arrow"
              @click="onClickAccordion(index)"
            >
              <template #title>
                <h4 :class="$style.title">{{ navigation.title }}</h4>
              </template>
              <template #content>
                <div :class="$style.content">
                  <AtomsConsumerLink
                    v-for="nameAndUrl in navigation.category_urls"
                    :key="nameAndUrl?.name"
                    :url="nameAndUrl?.url"
                    :class="$style.nav_link"
                    >{{ nameAndUrl?.name }}</AtomsConsumerLink
                  >
                </div>
              </template>
            </MoleculesConsumerAccordion>
          </div>
          <div :class="$style.corporation_wrap">
            <AtomsConsumerLink url="/page/business">
              <AtomsConsumerImage
                src="/images/site/footer/corporation.jpg"
                alt="法人の客様はこちら"
                :class="$style.corporation_img"
                :width="200"
                :height="100"
              />
              <p> 法人のお客様はこちら</p>
            </AtomsConsumerLink>
          </div>
          <div :class="$style.footer_logo_wrap_pc">
            <MoleculesConsumerTentialLogoLink variant="multiple" :width="70" :height="70" :class="$style.logo" />
            <OrganismsSns :class="$style.sns_wrap" />
          </div>
        </div>
        <div :class="[$style.footer_bottom, { [$style.simple]: isSimple }]">
          <div v-if="!isOverseasPage" :class="$style.footer_bottom_menu">
            <AtomsConsumerLink v-for="menu in bottomMenu" :key="menu.title" :url="menu.url">
              <li>{{ menu.title }}</li>
            </AtomsConsumerLink>
          </div>
          <div v-if="!isOverseasPage" :class="$style.footer_logo_wrap">
            <MoleculesConsumerTentialLogoLink variant="multiple" :width="58" :height="58" />
            <OrganismsSns :class="$style.sns_wrap" />
          </div>
          <p :class="$style.copyright">©{{ year }} TENTIAL</p>
        </div>
      </div>
    </footer>
  </ClientOnly>
</template>

<script setup lang="ts">
import { useOverseasStore } from "~/stores/overseas"
import { useStore } from "~/stores"
import type { ApiLayoutOne } from "~/types/server/api/layout"

withDefaults(
  defineProps<{
    navigations: ApiLayoutOne["navigation"]
  }>(),
  {
    navigations: () => [],
  },
)
const store = useStore()
const overseasStore = useOverseasStore()
const year = ref<Number>(0)
const route = useRoute()

const isSimple = computed(() => {
  // 簡素表示するページのパス
  const targetPath = ["/order/new"]
  return targetPath.includes(route.path)
})

const isSigned = computed(() => {
  return store.user.isSigned
})

const bottomMenu = [
  {
    title: "運営会社について",
    url: "https://corp.tential.jp/",
  },
  {
    title: "採用情報",
    url: "https://corp.tential.jp/recruit/?utm_source=tential_ec&utm_medium=referral",
  },
  {
    title: "プライバシーポリシー",
    url: "https://corp.tential.jp/privacy/",
  },
  {
    title: "特定商取引法の表示",
    url: "/law_info",
  },
  {
    title: "利用規約",
    url: "/terms",
  },
]

const selectAccordions = ref<number[]>([])

const onClickAccordion = (index: number) => {
  if (selectAccordions.value.includes(index)) {
    selectAccordions.value = selectAccordions.value.filter((i) => i !== index)
  } else {
    selectAccordions.value = [...selectAccordions.value, index]
  }
}

year.value = new Date().getFullYear()

const isOverseasPage = computed(() => {
  return overseasStore.isOverseasPage
})
</script>
<style module scoped lang="scss">
.footer {
  @include main-font;
  background-color: $primary-10;
  padding: 2rem 0.5rem 1.875rem;
  list-style: none;
  display: flex;
  justify-content: center;
  @include md {
    padding: 3.75rem 0;
    padding-top: 3rem;
  }
  .nav_link {
    @include main-font;
    color: $primary-100;
    font-weight: 600;
    margin-bottom: 0.5rem;
    display: block;
    &:hover {
      text-decoration: underline;
    }
  }
  .sns_wrap {
    margin-top: 1.25rem;
    margin-bottom: 1.25rem;
    @include md {
      margin-top: 5rem;
    }
  }
  .footer_inner {
    @include container-lg;
    .footer_top {
      @include md {
        display: flex;
        gap: 5vw;
        margin-bottom: 2.5rem;
      }
      .button_group {
        gap: 0.5rem;
        display: flex;
        flex-direction: column;
        margin-bottom: 2.5rem;
        white-space: nowrap;
        @include md {
          width: 220px;
          margin-bottom: 0;
        }
      }
      .link {
        display: block;
      }
      .nav_links_group {
        display: none;
        @include lg {
          display: grid;
          grid-template-columns: repeat(3, minmax(0, 1fr));
          gap: 2rem;
        }
        li {
          list-style: none;
        }
        .nav_links_title {
          @include main-font;
          color: $primary-60;
          font-size: $font-size-12;
        }
        .nav_links_wrap {
          margin-top: 1rem;
          .nav_link_wrap {
            @include main-font;
            margin-bottom: 0.5rem;
            color: $primary-100;
            font-size: $font-size-13;
          }
        }
      }
      .accordion_wrap {
        display: flex;
        flex-direction: column;
        width: 100%;
        @include md {
          width: 200px;
        }
        @include lg {
          display: none;
        }
        .accordion {
          border-top: 1px solid $secondary-40;
          padding: 1rem 0;
          &.last_accordion {
            border-bottom: 1px solid $secondary-40;
          }
          &.active {
            border-top: 1px solid $primary-100;
            .title {
              color: $primary-100;
            }
          }
          .title {
            color: $secondary-100;
            font-size: $font-size-13;
          }
          .content {
            margin-top: 0.75rem;
          }
        }
      }
      .corporation_wrap {
        display: flex;
        flex-direction: column;
        gap: 0.5rem;
        margin-top: 2.5rem;
        @include md {
          margin-top: 0;
        }
        p {
          font-size: $font-size-12;
          font-weight: 400;
          color: $primary-100;
          margin-top: 0.5rem;
        }
        .corporation_img {
          max-width: 200px;
          aspect-ratio: 2 / 1;
          object-fit: cover;
          @include md {
            width: 15vw;
          }
        }
      }
      .footer_logo_wrap_pc {
        display: none;
        @include md {
          margin-left: auto;
          display: flex;
          gap: 1.5rem;
          flex-direction: column;
          justify-content: center;
          align-items: flex-end;
        }
      }
    }
    .footer_bottom {
      @include md {
        border-top: 1px solid $primary-40;
        padding-top: 2.5rem;
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        &.simple {
          border-top: none;
          padding-top: 0;
        }
      }
      .footer_bottom_menu {
        margin-top: 1.5rem;
        font-size: $font-size-11;
        padding-bottom: 1rem;
        border-bottom: 1px solid $primary-40;
        gap: 0.5rem;
        display: grid;
        a {
          color: $primary-80;
        }
        @include md {
          gap: 1.5rem;
          border-bottom: none;
          display: flex;
          padding-bottom: 0;
          margin-top: 0;
          font-size: $font-size-12;
        }
      }
      .footer_logo_wrap {
        display: flex;
        gap: 1.5rem;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin-top: 2.5rem;
        margin-bottom: 1.5rem;
        @include md {
          display: none;
        }
      }
      .copyright {
        @include main-font;
        color: $primary-60;
        text-align: center;
        font-size: $font-size-10;
        @include md {
          font-size: $font-size-12;
        }
      }
    }
  }
}
</style>
