<template>
  <div class="order-cart-price">
    <div class="_sub-total _price-column flex">
      <p class="_key">{{ getOverseasTxtByLang(language, "amountIncludingTax") }}</p>
      <p class="_value"
        >¥{{ subTotal.toLocaleString() }}<span class="doll_price">({{ getOverseasRange(language, subTotal) }})</span></p
      >
    </div>
    <div v-if="discountPrice > 0" class="_discount-price _price-column flex">
      <p class="_key">{{ getOverseasTxtByLang(language, "discount") }}</p>
      <p class="_value">¥-{{ discountPrice.toLocaleString() }}</p>
    </div>
    <div class="_total-price _price-column flex">
      <p class="_key">{{ getOverseasTxtByLang(language, "totalAmount") }}</p>
      <p class="_value"
        >¥{{ total.toLocaleString() }}<span class="doll_price">({{ getOverseasRange(language, total) }})</span></p
      >
    </div>
    <p class="order-cart-price-note">
      {{ getOverseasTxtByLang(language, "shippingNoteContent") }}
    </p>
  </div>
</template>

<script setup lang="ts">
import { getOverseasRange, getOverseasTxtByLang } from "~/utils/functions/overseas"

defineProps({
  subTotal: {
    type: Number,
    required: true,
  },
  total: {
    type: Number,
    required: true,
  },
  postagePrice: {
    type: Number,
    default: 0,
  },
  discountPrice: {
    type: Number,
    default: 0,
  },
  subscriptionDiscountPrice: {
    type: Number,
    default: 0,
  },
  commissionPrice: {
    type: Number,
    default: 0,
  },
  giftPrice: {
    type: Number,
    default: 0,
  },
  embroideryPrice: {
    type: Number,
    default: 0,
  },
  remainAmount: {
    type: Number,
    default: 0,
  },
  recommendDiscountMethod: {
    type: String,
    default: "",
  },
  recommendDiscountRate: {
    type: Number,
    default: 0,
  },
  recommendDiscountFixedAmount: {
    type: Number,
    default: 0,
  },
  isCouponApplied: {
    type: Boolean,
    default: false,
  },
})
const language = navigator.language
</script>
<style lang="scss" scoped>
.order-cart-price-note {
  font-size: 12px;
  margin-top: 8px;
  line-height: 1.5;
}
</style>
